/* eslint-disable no-new */
/*-----------------------------------------------
|                    Esplatform Offcanvas
-----------------------------------------------*/

const esplatformOffcanvasInit = () => {
  const { getData } = window.esplatform.utils;
  const toggleEls = document.querySelectorAll(
    "[data-esplatform-toggle='offcanvas']"
  );
  const offcanvasBackdrop = document.querySelector('[data-esplatform-backdrop]');

  const showFilterCol = offcanvasEl => {
    offcanvasEl.classList.add('show');
    document.body.style.overflow = 'hidden';
  };
  const hideFilterCol = offcanvasEl => {
    offcanvasEl.classList.remove('show');
    document.body.style.removeProperty('overflow');
  };

  if (toggleEls) {
    toggleEls.forEach(toggleEl => {
      const offcanvasTarget = getData(toggleEl, 'esplatform-target');
      const offcanvasTargetEl = document.querySelector(offcanvasTarget);
      const closeBtn = offcanvasTargetEl.querySelector(
        "[data-esplatform-dismiss='offcanvas']"
      );

      toggleEl.addEventListener('click', () => {
        console.log({ offcanvasTargetEl });
        showFilterCol(offcanvasTargetEl);
      });
      if (closeBtn) {
        closeBtn.addEventListener('click', () => {
          hideFilterCol(offcanvasTargetEl);
        });
      }
      if (offcanvasBackdrop) {
        offcanvasBackdrop.addEventListener('click', () => {
          hideFilterCol(offcanvasTargetEl);
        });
      }
    });
  }
};

export default esplatformOffcanvasInit;
